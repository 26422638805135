//colors
$colorPrimary: #4abbc0;
$colorSecondary: #afe4e7;
$colorPrimaryDark: #17a8b0;

$colorNeutralVeryDark: rgb(40, 40, 40);
$colorNeutralDark: rgb(80, 80, 80);
$colorNeutral: rgb(180, 180, 180);
$colorNeutralLight: rgb(220, 220, 220);

$colorAlert: #db295b;

//fonts
$webFont: "Poppins", sans-serif;

//fonts' sizes
$h1desktop: 3rem;
$h2desktop: 2rem;
$h3desktop: 1.5rem;
$h4desktop: 1.25rem;
$pdesktop: 1.25rem;

$h1mobile: 2.2rem;
$h2mobile: 1.5rem;
$h3mobile: 1.25rem;
$h4mobile: 1rem;
$pmobile: 1rem;



//spaces
$space1: 0.5rem;
$space2: 1rem;
$space3: 1.5rem;
$space4: 2rem;
$space5: 5rem;


//borders
$borderRadius: .3rem; 


//screen sizes
$tablet: "min-width: 900px";
$desktop: "min-width: 1200px";