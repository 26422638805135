@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}
body {
  font-family: "Poppins", sans-serif;
  max-width: 100vw;
  overflow-x: hidden;
  h1 {
    @include websiteFont(700, white, $h1mobile) {
      margin: $space4 0 $space1 0;
      max-width: 800px;
    }
  }
  h2 {
    @include websiteFont(600, white, $h2mobile) {
      margin: $space1 0;
      margin-bottom: $space3;
      max-width: 600px;
    }
  }
  h3 {
    @include websiteFont(500, white, $h3mobile) {
      margin: $space2 0;
      text-transform: uppercase;
    }
    span {
      @media screen and ($tablet) {
        display: inline-block;
        border-bottom: 2px solid white;
        width: 10%;
        position: relative;
        bottom: 0.5rem;
        &:first-of-type {
          margin-right: 1rem;
        }
        &:last-of-type {
          margin-left: 1rem;
        }
      }
    }
  }
  h4 {
    @include websiteFont(500, $colorNeutralDark, $h4mobile) {
      margin: $space4 0;
    }
  }
  p {
    @include websiteFont(400, white, $pmobile) {
      margin: 1rem 0;
    }
  }
  ul {
    margin-left: 1.25rem;
    li {
      list-style-type: disc;
      color: white;
      list-style-position: outside;
      @include websiteFont(400, white, $pmobile);
      span {
        display: inline-block;
      }
    }
  }
  @media screen and ($tablet) {
    h1 {
      font-size: $h1desktop;
    }
    h2 {
      font-size: $h2desktop;
    }
    h3 {
      font-size: $h3desktop;
    }
    h4 {
      font-size: $h4desktop;
    }
    p {
      font-size: $pdesktop;
    }
    ul li {
      font-size: $pdesktop;
    }
  }
  a {
    font-family: $webFont;
    text-decoration: none;
    color: inherit;
    &.primary {
      display: inline-block;
      @include button($colorPrimary, none, white);
      min-width: 130px;
      &:hover {
        background-color: $colorPrimaryDark;
      }
    }
  }
  button.primary {
    @include button($colorPrimary, none, white);
    font-family: $webFont;
    min-width: 130px;
    &:hover {
      background-color: $colorPrimaryDark;
    }
  }
  button.secondary {
    @include button(transparent, 1px solid $colorPrimary, $colorPrimary);
    font-family: $webFont;
    padding: $space1 $space2;
    &:hover {
      background-color: $colorNeutralDark;
    }
  }
  .container {
    width: 90%;
    margin: 0 auto;

    @media screen and ($tablet) {
      width: 85%;
    }
  }
}
.background-image {
  min-height: 100vh;
  width: calc(100% + 1px);
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("../assets/bgMobile.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  left: -1px;
  z-index: -1;

  @media screen and ($desktop) {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url("../assets/bg.jpg");
  }
}

.cookies {
  width: 100vw;
  position: fixed;
  bottom: 0;
  background-color: $colorNeutralVeryDark;
  z-index: 10;
  padding: 0 5%;
  @media screen and ($tablet) {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: $space2;
  }
  button.secondary {
    margin: $space2 0;
  }
  p {
    @include websiteFont(300, white, 0.8rem);
    max-width: 90%;
  }
}
.d-none {
  display: none;
}
