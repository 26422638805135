/* http://meyerweb.com/eric/tools/css/reset/
   v2.0-modified | 20110126
   License: none (public domain)
*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* make sure to set some focus styles for accessibility */
:focus {
  outline: 0;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type=search] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  max-width: 100%;
}

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */
[hidden] {
  display: none;
}

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */
html {
  font-size: 100%; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
  -ms-text-size-adjust: 100%; /* 2 */
}

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */
a:focus {
  outline: thin dotted;
}

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */
a:active,
a:hover {
  outline: 0;
}

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */
img {
  border: 0; /* 1 */
  -ms-interpolation-mode: bicubic; /* 2 */
}

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */
figure {
  margin: 0;
}

/**
 * Correct margin displayed oddly in IE 6/7.
 */
form {
  margin: 0;
}

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0; /* 1 */
  padding: 0;
  white-space: normal; /* 2 */
  *margin-left: -7px; /* 3 */
}

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */
button,
input,
select,
textarea {
  font-size: 100%; /* 1 */
  margin: 0; /* 2 */
  vertical-align: baseline; /* 3 */
  *vertical-align: middle; /* 3 */
}

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
button,
input {
  line-height: normal;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */
button,
select {
  text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */
button,
html input[type=button],
input[type=reset],
input[type=submit] {
  -webkit-appearance: button; /* 2 */
  cursor: pointer; /* 3 */
  *overflow: visible; /* 4 */
}

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default;
}

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */
input[type=checkbox],
input[type=radio] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
  *height: 13px; /* 3 */
  *width: 13px; /* 3 */
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */
input[type=search] {
  -webkit-appearance: textfield; /* 1 */
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box; /* 2 */
  box-sizing: content-box;
}

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * Remove inner padding and border in Firefox 3+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */
textarea {
  overflow: auto; /* 1 */
  vertical-align: top; /* 2 */
}

/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

html,
button,
input,
select,
textarea {
  color: #222;
}

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

img {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

body button.secondary, body button.primary, body a.primary {
  padding: 1rem 2rem;
  margin: 2rem 0;
  border-radius: 0.3rem;
  font-weight: 500;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 140%;
  text-align: center;
  cursor: pointer;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Poppins", sans-serif;
  max-width: 100vw;
  overflow-x: hidden;
}
body h1 {
  font-size: 2.2rem;
  font-weight: 700;
  color: white;
  line-height: 150%;
  letter-spacing: 0.5px;
  margin: 2rem 0 0.5rem 0;
  max-width: 800px;
}
body h2 {
  font-size: 1.5rem;
  font-weight: 600;
  color: white;
  line-height: 150%;
  letter-spacing: 0.5px;
  margin: 0.5rem 0;
  margin-bottom: 1.5rem;
  max-width: 600px;
}
body h3 {
  font-size: 1.25rem;
  font-weight: 500;
  color: white;
  line-height: 150%;
  letter-spacing: 0.5px;
  margin: 1rem 0;
  text-transform: uppercase;
}
@media screen and (min-width: 900px) {
  body h3 span {
    display: inline-block;
    border-bottom: 2px solid white;
    width: 10%;
    position: relative;
    bottom: 0.5rem;
  }
  body h3 span:first-of-type {
    margin-right: 1rem;
  }
  body h3 span:last-of-type {
    margin-left: 1rem;
  }
}
body h4 {
  font-size: 1rem;
  font-weight: 500;
  color: rgb(80, 80, 80);
  line-height: 150%;
  letter-spacing: 0.5px;
  margin: 2rem 0;
}
body p {
  font-size: 1rem;
  font-weight: 400;
  color: white;
  line-height: 150%;
  letter-spacing: 0.5px;
  margin: 1rem 0;
}
body ul {
  margin-left: 1.25rem;
}
body ul li {
  list-style-type: disc;
  color: white;
  list-style-position: outside;
  font-size: 1rem;
  font-weight: 400;
  color: white;
  line-height: 150%;
  letter-spacing: 0.5px;
}
body ul li span {
  display: inline-block;
}
@media screen and (min-width: 900px) {
  body h1 {
    font-size: 3rem;
  }
  body h2 {
    font-size: 2rem;
  }
  body h3 {
    font-size: 1.5rem;
  }
  body h4 {
    font-size: 1.25rem;
  }
  body p {
    font-size: 1.25rem;
  }
  body ul li {
    font-size: 1.25rem;
  }
}
body a {
  font-family: "Poppins", sans-serif;
  text-decoration: none;
  color: inherit;
}
body a.primary {
  display: inline-block;
  background-color: #4abbc0;
  border: none;
  color: white;
  min-width: 130px;
}
body a.primary:hover {
  background-color: #17a8b0;
}
body button.primary {
  background-color: #4abbc0;
  border: none;
  color: white;
  font-family: "Poppins", sans-serif;
  min-width: 130px;
}
body button.primary:hover {
  background-color: #17a8b0;
}
body button.secondary {
  background-color: transparent;
  border: 1px solid #4abbc0;
  color: #4abbc0;
  font-family: "Poppins", sans-serif;
  padding: 0.5rem 1rem;
}
body button.secondary:hover {
  background-color: rgb(80, 80, 80);
}
body .container {
  width: 90%;
  margin: 0 auto;
}
@media screen and (min-width: 900px) {
  body .container {
    width: 85%;
  }
}

.background-image {
  min-height: 100vh;
  width: calc(100% + 1px);
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("../assets/bgMobile.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  left: -1px;
  z-index: -1;
}
@media screen and (min-width: 1200px) {
  .background-image {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../assets/bg.jpg");
  }
}

.cookies {
  width: 100vw;
  position: fixed;
  bottom: 0;
  background-color: rgb(40, 40, 40);
  z-index: 10;
  padding: 0 5%;
}
@media screen and (min-width: 900px) {
  .cookies {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
}
.cookies button.secondary {
  margin: 1rem 0;
}
.cookies p {
  font-size: 0.8rem;
  font-weight: 300;
  color: white;
  line-height: 150%;
  letter-spacing: 0.5px;
  max-width: 90%;
}

.d-none {
  display: none;
}

header {
  display: none;
}

@media screen and (min-width: 1200px) {
  .hero {
    height: 100vh;
    display: flex;
    flex-basis: 0;
  }
  .hero > section {
    flex-grow: 1;
    flex-basis: 0;
  }
}
.hero > section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2rem 0;
}
@media screen and (min-width: 900px) {
  .hero > section > div {
    width: 70%;
  }
}
.hero > section:nth-child(2) {
  background-color: rgba(255, 255, 255, 0.8);
}
.hero > section:nth-child(2) h4 {
  color: rgb(80, 80, 80);
  text-transform: uppercase;
}
.hero form {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.hero form label {
  font-size: 0.8rem;
  font-weight: 500;
  color: rgb(40, 40, 40);
  line-height: 150%;
  letter-spacing: 0.5px;
  margin: 0 0.5rem 0 0;
}
.hero form input,
.hero form textarea {
  width: 100%;
  border-radius: 0.3rem;
  border: none;
  margin-top: 0.25rem;
  margin-bottom: 0.5rem;
  padding: 0 1rem;
  font-family: "Poppins", sans-serif;
  font-size: 0.8rem;
}
.hero form input:focus,
.hero form textarea:focus {
  border: 1px solid #4abbc0;
}
.hero form input::placeholder,
.hero form textarea::placeholder {
  opacity: 0.5;
}
.hero form input:not([type=checkbox]) {
  min-height: 2.5rem;
}
.hero form textarea {
  padding-top: 1rem;
  height: 5rem;
}
.hero form > div {
  padding: 0.25rem 0;
}
.hero form input[type=checkbox] {
  width: 1rem;
  height: 1rem;
  line-height: 0;
  margin-top: 0;
  margin-bottom: 0;
  position: relative;
  top: 3px;
  color: white;
}
.hero form input[type=checkbox]:checked {
  accent-color: #4abbc0;
}
.hero form .checkbox-container {
  margin: 0;
  padding: 0;
  position: relative;
}
.hero form .checkbox-container label {
  font-size: 0.7rem;
}
.hero form .checkbox-container .tooltip {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s;
  position: absolute;
  bottom: 150%;
  z-index: 1;
  background-color: rgb(40, 40, 40);
  font-size: 0.7rem;
  font-weight: 400;
  color: white;
  line-height: 150%;
  letter-spacing: 0.5px;
  padding: 1rem;
  width: 70vw;
  left: -1rem;
  border-radius: 0.3rem;
}
@media screen and (min-width: 900px) {
  .hero form .checkbox-container .tooltip {
    width: 400px;
  }
}
.hero form .checkbox-container .tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: rgb(40, 40, 40) transparent transparent transparent;
}
@media screen and (max-width: 899px) {
  .hero form .checkbox-container:focus-within .tooltip {
    visibility: visible;
    opacity: 1;
  }
}
@media screen and (min-width: 1200px) {
  .hero form .checkbox-container:hover .tooltip {
    visibility: visible;
    opacity: 1;
  }
}
.hero form button {
  align-self: center;
  width: 100%;
  display: flex;
  justify-content: center;
}
@media screen and (min-width: 900px) {
  .hero form button {
    width: auto;
  }
}
.hero form .lds-ring {
  display: none;
  position: relative;
  width: 22px;
  height: 22px;
}
.hero form .lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 22px;
  height: 22px;
  border: 2px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.hero form .lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.hero form .lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.hero form .lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.hero .input-error {
  border: 1px solid #db295b;
}
.hero .error-message {
  font-size: 0.8rem;
  font-weight: 500;
  color: #db295b;
  line-height: 150%;
  letter-spacing: 0.5px;
  display: inline-block;
}